/*
 * @Autor: syq
 * @Date: 2021-07-12 16:37:32
 */

import request from "../../request/request";
import { stringify } from "qs";

//! 获取商品列表
// currentPage=${currentPage}&qp-frontCategoryId-eq=${params}
const getItemList = async (params) =>{
  return request({
    url: `/frontCategory/getPagerItemByFrontCategoryId?${stringify(params)}`,
    method: "get",
  })
}

//! 店铺
// currentPage=${currentPage}&qp-frontCategoryId-eq=${params}
const getStoreItemList = async (params) =>{
  return request({
    url: `/frontCategory/getPagerItemByStoreFrontCategoryId?${stringify(params)}`,
    method: "get",
  })
}

// ! 首页更多跳转
// currentPage, brandId, classid, spuid
const getTotalItemList = async (params) => {
  return request({
    url:`/construct/getItemList?${stringify(params)}`,
    method: "get",
  })
};

export { getItemList, getTotalItemList, getStoreItemList };
