<template>
  <div id="theme">
    <div class="theme-header">
      <div class="theme-header-layout">
        <div
          :class="[sortStatus === 0 ? 'layout-active' : '', 'layout-left']"
          @click="sortStatusChange(0)"
        >
          综合
        </div>
        <div class="layout-center" @click="sortStatusChange(1)">
          <span
            :class="[
              'layout-center-text',
              sortStatus === 1 || sortStatus === 2 ? 'layout-active' : '',
            ]"
          >
            按价格&nbsp;
          </span>
          <span class="layout-center-arrow">
            <van-icon v-if="sortStatus === 1" :name="topIcon" :size="18" />
            <van-icon
              v-else-if="sortStatus === 2"
              :name="doneIcon"
              :size="18"
            />
            <van-icon v-else :name="defaultIcon" :size="18" />
          </span>
        </div>
        <div
          class="layout-right"
          @click="sortStatusChange(3)"
        >
       <span
            :class="[
              'layout-center-text',
              sortStatus === 3 || sortStatus === 4 ? 'layout-active' : '',
            ]"
          >
            按销量&nbsp;
          </span>
          <span class="layout-center-arrow">
            <van-icon v-if="sortStatus === 3" :name="topIcon" :size="18" />
            <van-icon
              v-else-if="sortStatus === 4"
              :name="doneIcon"
              :size="18"
            />
            <van-icon v-else :name="defaultIcon" :size="18" />
          </span>
        </div>
      </div>
    </div>
    <div class="theme-content">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
      >
        <van-card
          v-for="item in items"
          :key="item.id"
          :title="item.name"
        >  
          <template #thumb>
             <van-image v-if="findFileType(item)===1" :src="findUrl(item)" />
             <video @click.stop="(e)=>videoStop(e)" v-if="findFileType(item)===2" :src="findUrl(item)" controls class="videoStyle"></video>
          </template>
          <template #price>
            <span>{{ item.price ? `¥${item.price.toFixed(2)}` : '' }}</span>
          </template>
        </van-card>
      </van-list>
    </div>
  </div>
</template>

<script>
import defaultIcon from '../../assets/icon/screening_initial_icon@2x.png'
import doneIcon from '../../assets/icon/screening_done_icon@2x.png'
import topIcon from '../../assets/icon/screening_top_icon@2x.png'
import { swipeType } from '../../utils/homeToTheme'
import { Toast } from 'vant'
import { getItemList, getTotalItemList, getStoreItemList } from './service'
const type = {
  brandId: 'brandId',
  classId: 'classId',
  itemCode: 'itemCode',
  activityNo:'activityNo'
}
const sortType = {
  0: '综合',
  1: '按价格上',
  2: '按价格下',
  3: '按销量上',
  4 : '按销量下',
}
const sortTypeToReqParams = {
  0: {
    key: 'sorter-createTime',
    value: 'desc',
  },
  1: {
    key: 'sorter-price',
    value: 'desc',
  },
  2: {
    key: 'sorter-price',
    value: 'asc',
  },
  3: {
    key: 'sorter-quantity',
    value: 'desc',
  }, 
  4: {
    key: 'sorter-quantity',
    value: 'asc',
  },
}
export default {
  data() {
    return {
      sortTypeToReqParams,
      doneIcon,
      topIcon,
      defaultIcon,
      sortStatus: 0,
      sortType,
      items: [],
      loading: false,
      finished: false,
      currentPage: 1,
    }
  },
  mounted() {
    this.initData()
  },
  computed: {
    storeCode() {
      return this.$route.query["storeCode"]; 
    }
  },
  methods: {
    videoStop(e){
      e.stopPropagation()
    },
    // 排序类型切换
    sortStatusChange(number) {
      if (this.sortStatus === 1 && number === 1) {
        this.sortStatus = 2
        this.initData()
        return
      } else if(this.sortStatus === 3 && number === 3){
        this.sortStatus = 4
        this.initData()
        return      
        }
      this.sortStatus = number
      this.initData()
    },
    // 判断初始化调用方法
    initData() {
      const {
        query: { themId },
      } = this.$route
      // debugger
      if (themId) {
        this.queryItemList()
        return
      }
      this.getTotalItemList()
    },
    // 判断load加载调用方法
    loadInitData() {
      const {
        query: { themId },
      } = this.$route
      if (themId) {
        this.loadItemList()
        return
      }
      this.loadTotalItemList()
    },

    // 初始化调用(更多)
    async getTotalItemList() {
      const { query } = this.$route
      const params = {
        currentPage: this.currentPage,
        [this.sortTypeToReqParams[this.sortStatus].key]:
          this.sortTypeToReqParams[this.sortStatus].value,
      }
      Object.keys(query).forEach((i) => {
        if (Object.values(swipeType).includes(i)) {
          params[type[i]] = query[i]
        }
      })

      if (this.$route.query.storeCode) {
        params['storeCode'] = this.$route.query.storeCode
      }

      this.loading = true

      const res = await getTotalItemList(params)
      if (res.status === '0') {
        this.items = res?.data?.items
        this.currentPage = Number(res?.data?.page)
        this.loading = false
        this.finished =!res?.data?.items?.length < 10
      } else {
        this.loading = false
        this.finished = true
        Toast.fail(res.msg)
      }
    },

    // 初始化调用(商品列表)
    async queryItemList() {
      const params = {
        currentPage: this.currentPage,
        [this.sortTypeToReqParams[this.sortStatus].key]:
          this.sortTypeToReqParams[this.sortStatus].value,
        'qp-frontCategoryId-eq': this.$route.query.themId,
      }
      this.loading = true
      // debugger
      const res = this.$route.query.storeCode
        ? await getStoreItemList(params)
        : await getItemList(params)
      if (res.status === '0') {
        this.items = res?.data?.items
        this.currentPage = Number(res?.data?.page)
        this.finished = res?.data?.items?.length < 10
        this.loading = false
      } else {
        this.finished = true
        this.currentPage = 1
        this.finished = false
        Toast.fail(res.msg)
      }
    },

    async loadTotalItemList() {
      const { query } = this.$route
      const params = {
        [this.sortTypeToReqParams[this.sortStatus].key]:
          this.sortTypeToReqParams[this.sortStatus].value,
      }
      Object.keys(query).forEach((i) => {
        if (Object.values(swipeType).includes(i)) {
          params[type[i]] = query[i]
        }
      })
      if (!this.finished) {
        params.currentPage = this.currentPage + 1
      }

      this.loading = true
      const res = await getTotalItemList(params)

      if (res.status === '0') {
        this.items = this.items.concat(res.data.items)
        this.currentPage = Number(res?.data?.page)
        this.loading = false
        this.finished = res?.data?.items?.length < 10
        if (!res?.data) {
          this.finished = true
        }
      } else {
        this.loading = false
        this.finished = true
        Toast.fail(res.msg)
      }
    },

    async loadItemList() {
      const params = {
        [this.sortTypeToReqParams[this.sortStatus].key]:
          this.sortTypeToReqParams[this.sortStatus].value,
        'qp-frontCategoryId-eq': this.$route.query.themId,
      }
      if (!this.finished) {
        params.currentPage = this.currentPage + 1
      }
      this.loading = true
      const res = this.$route.query.storeCode
        ? await getStoreItemList(params)
        : await getItemList(params)

      if (res.status === '0') {
        this.items = this.items.concat(res.data.items)
        this.currentPage = Number(res.data.page)
        this.finished = res.data.items.length < 10
        this.loading = false
      } else {
        this.finished = true
        this.loading = false
        Toast.fail(res.msg)
      }
    },

    findFileType(item){
      return item?.mediaList? item.mediaList[0].type : 1
    },

    findUrl(item) {
      return item?.mediaList && item.mediaList[0]?.url
        ? item.mediaList[0].url
        : 'http://uat-oss-business-middle-platform.oss-cn-shenzhen.aliyuncs.com/202108202047596941428700340054843393.png?Expires=1944823679&OSSAccessKeyId=LTAI5tMBSdRFFsKwy54k3F3J&Signature=c%2B%2FQNCjX6kK7mi1wrIZlpdJjCMg%3D'
    },
    shopdetails(item) {
      const { itemCode, storeCode } = item
      this.$router.push({
        path: '/shopdetails',
        query: { itemCode, storeCode },
      })
    },
  },
}
</script>

<style lang="less" scoped>
#theme {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: @BackgroundColor;
  .theme-header {
    width: 100%;
    height: 42px;
    background-color: #fff;
    .theme-header-layout {
      width: 100%;
      height: 100%;
      display: flex;
      border-bottom: 1px solid #e9e9e9;
      .layout-left,
      .layout-center,
      .layout-right {
        flex: 1;
        line-height: 42px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        font-family: PingFangSC, PingFangSC-Regular;
      }
      .layout-active {
        color: #0095da;
      }
      .layout-center {
        display: flex;
        justify-content: center;
        align-items: center;
        // .layout-center-text {
        //   // width: 60px;
        // }
        .layout-center-arrow {
          width: 20px;
          display: flex;
          flex-direction: column;
        }
      }
       .layout-right{
        display: flex;
        justify-content: center;
        align-items: center;
         .layout-center-arrow {
          width: 20px;
          display: flex;
          flex-direction: column;
        }
       }
    }
  }
  .theme-content {
    flex: 1;
    overflow: auto;
    background: @BackgroundColor;
    /deep/ .van-card {
      background: #fff;
      .van-card__thumb {
        width: 99px;
        height: 99px;
      }
      .van-card__content {
        margin-left: 4px;
        .van-card__title {
          width: 100%;
          height: 48px;
          max-height: 48px;
          line-height: 24px;
          font-size: 16px;
          font-weight: 500;
          font-family: PingFangSC, PingFangSC-Medium;
          color: #000;
          overflow: hidden;
          display: -webkit-box; //将元素设为盒子伸缩模型显示
          -webkit-box-orient: vertical; //伸缩方向设为垂直方向
          -webkit-line-clamp: 2; //超出2行隐藏，并显示省略号
        }
        .van-card__price {
          width: 100%;
          height: 24px;
          line-height: 24px;
          font-size: 16px;
          font-family: Montserrat, Montserrat-Medium;
          font-weight: 500;
          color: #ff3228;
        }
      }
    }
    /deep/ .van-card:not(:first-child) {
      margin-top: 0;
    }
  }
  .videoStyle{
    width: 99px;
    height: 99px;
  }
  .van-image{
    width:99px;
    height: 99px;
  }
}
</style>
<style lang="less" scoped>
.mengban{
  position: fixed;
  top: 42px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}
</style>